import React from 'react'
import { createRoot } from 'react-dom/client';
import App from '@tmt-insights/oms_fe_core'
import './index.scss';
import config from './configs'

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <App config={config} />
)
